<template>
  <div id="app">
    <ezvizViewer></ezvizViewer>
  </div>
</template>

<script>
  import ezvizViewer from "./components/ezviz_viewer";
  import bus from "./utils/bus";
  import {getIndex, getTargetHost, setTargetHost} from "@/utils/util";

  export default {
    components: {ezvizViewer},
    data() {
      return {
        index: "",
      };
    },
    created() {
      this.index=getIndex()
    },
    methods: {
      getEzvizInfo() {
          window.addEventListener("message", (event) => {
            let message = event.data;
            switch (message.channel) {
              case "ezvizUserInfo":
                if (this.index !== message.data.index) return;
                console.error(this.index, message.data.index);
                bus.$emit("ezvizUserInfo", message.data);
                break;
              case "reconnect":
                if (this.index !== message.data.index) return;
                bus.$emit("reconnect");
                break;
              case "talk":
                if (this.index !== message.data.index) return;
                bus.$emit("talk",message.data.talkStatus);
                break;
              case "stopTalk":
                if (this.index !== message.data.index) return;
                bus.$emit("startTalk");
                break;
              case "setOutPutState":
                if (this.index !== message.data.index) return;
                bus.$emit("setOutPutState",message.data.state);
                break;
              case "getFrameImage":
                if (this.index !== message.data.index) return;
                bus.$emit("getFrameImage");
                break;
              case "setIndex":
                bus.$emit("setIndex", message.data);
                break;
              case "resizeCanvas":
                if (this.index !== message.data.index) return;
                bus.$emit("resizeCanvas", message.data);
                break;
              case "setTargetHost":
                this.targetHost = message.data.targetHost
                setTargetHost(this.targetHost)
                window.parent.postMessage(
                    {
                        channel: "getEzvizInfo",
                        data: {
                            index:this.index,
                        },
                    },
                    getTargetHost()
                  );

                  break;
            }
          });
      },
    },
    mounted() {
      this.getEzvizInfo();
    },
  };
</script>

<style lang="scss">
  @import "./assets/css/common.scss";

  ::-webkit-scrollbar {
    display: none;
  }

  body {
    margin: 0;
  }

  #app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    background-color: #1c1c1c;
  }
</style>
