<template>
  <div id="ezviz_viewer"></div>
</template>
<script>
import EZUIKit from "../../lib/ezuikit.js";
import bus from "../../utils/bus";
import $ from "jquery";
import {getIndex, getTargetHost} from "@/utils/util";

export default {
  data() {
    return {
      EZUIKitPlayer: "",
      deviceParams: null
    };
  },
  created() {
    this.index = getIndex()

    this.initEvents();
  },
  mounted() {
  },
  methods: {
    initEvents() {
      bus.$on("ezvizUserInfo", (r) => {
        this.deviceParams = r;
        this.InitEZUIKitPlayer();
      });

      bus.$on("reconnect", () => {
        this.EZUIKitPlayer.play();
      });

      bus.$on("talk", this.startTalk)


      bus.$on("setOutPutState",this.setOutPutState)

      bus.$on("resizeCanvas", ({width, height}) => {
        if (this.EZUIKitPlayer) {
          if (height) {
            this.EZUIKitPlayer.reSize(height / (9 / 16), height);
          } else {
            this.EZUIKitPlayer.reSize(
              $(document).height() / (9 / 16),
              $(document).height()
            );
          }
        }
      });

      bus.$on("getFrameImage", () => {
        this.EZUIKitPlayer.capturePicture("ezvizImage", (r) => {
          window.parent.postMessage(
            {
              channel: "frameImage",
              data: {
                url: r.base64,
                index: this.index,
              },
            },
            getTargetHost()
          );
        });
      });

      window.addEventListener("resize", () => {
        this.resizeCanvas();
      });
    },
    resizeCanvas() {
      if (this.EZUIKitPlayer) {
        try {
          this.EZUIKitPlayer.reSize(
            $(document).height() / (9 / 16),
            $(document).height()
          );
        } catch (e) {
        }
      }
    },
    startTalk(status) {
      if (status) {
        this.EZUIKitPlayer.closeSound()
        this.EZUIKitPlayer.startTalk()
      } else {
        this.EZUIKitPlayer.stopTalk()
      }
    },
    InitEZUIKitPlayer() {
      const {token, url,deviceSerial} = this.deviceParams

      this.EZUIKitPlayer = new EZUIKit.EZUIKitPlayer({
        id: "ezviz_viewer", // 视频容器ID
        accessToken: token,
        url: url,
        autoplay: true,
        plugin: ["talk"],
        audio: 0,
        handleSuccess: () => {
          this.resizeCanvas();
          window.parent.postMessage(
            {
              channel: "play-success",
              data: {
                index: this.index,
                support: {
                  talk: this.EZUIKitPlayer.capacity['support_talk'] === '1'
                }
              },
            },
            getTargetHost()
          );

          if(deviceSerial){
            this.getDeviceIosStatus()
          }
        },
        handleTalkError: (res) => {
          window.parent.postMessage(
            {
              channel: "talk-error",
              data: {
                msg: res,
                index: this.index,
              },
            },
            getTargetHost()
          );
        },
        handleError: (e) => {
          window.parent.postMessage(
            {
              channel: "play-error",
              data: {
                index: this.index,
              },
            },
            getTargetHost()
          );
        },
      });
    },
    getDeviceIosStatus() {
      const {token, deviceSerial} = this.deviceParams
      $.ajax({
        url: `${ysApi}/api/hikvision/ISAPI/System/IO/outputs/1/status`, // The URL to which the request is sent
        method: 'GET', // The HTTP method (GET, POST, PUT, DELETE, etc.)
        headers: {
          'EZO-AccessToken': token,
          'EZO-DeviceSerial': deviceSerial,
          'Content-Type': 'application/xml'
        },
        success:  (response)=> {
          window.parent.postMessage(
            {
              channel: "io-state",
              data: {
                index: this.index,
                state: this.getValueFromXml(response,'ioState')
              },
            },
            getTargetHost()
          )
        }
      })
    },
    setOutPutState(state){
      const {token, deviceSerial} = this.deviceParams
      $.ajax({
        url: `${ysApi}/api/hikvision/ISAPI/System/IO/outputs/1/trigger`, // The URL to which the request is sent
        method: 'PUT', // The HTTP method (GET, POST, PUT, DELETE, etc.)
        headers: {
          'EZO-AccessToken': token,
          'EZO-DeviceSerial': deviceSerial,
          'Content-Type': 'application/xml'
        },

        data:`<?xml version: "1.0" encoding="utf-8"?><IOPortData><outputState>${state==='active'?'high':'low'}</outputState></IOPortData>`,
        success: (response)=> {
          let code=this.getValueFromXml(response,'subStatusCode')
          if(code==='ok'){
            window.parent.postMessage(
              {
                channel: "io-state",
                data: {
                  index: this.index,
                  state
                },
              },
              getTargetHost()
            )
          }
        },
        error: function(xhr, status, error) {

        }
      });

    },
    getValueFromXml(xmlString, tagName) {
      // 创建一个新的 DOMParser 实例
      const parser = new DOMParser();
      // 将 XML 字符串解析为 XML 文档
      const xmlDoc = parser.parseFromString(xmlString, "text/xml");
      // 获取指定标签的第一个节点
      const node = xmlDoc.getElementsByTagName(tagName)[0];
      // 如果节点存在，返回其文本内容，否则返回 null
      return node ? node.textContent : null;
    }
  },
};
</script>
<style lang="scss">
#ezviz_viewer-wrap {
  position: absolute;
  height: 100% !important;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  #ezviz_viewer {
    width: 100% !important;
    height: 100% !important;

    .hkEzvizVideo_1draw-window,
    .hkEzvizVideo_2draw-window {
      display: none;
    }
  }
}
</style>
